const routerViewMap = {
  Home: () => import('@/views/Home/HomeIndex'),
  Dashboard: () => import('@/views/Home/Dashboard/Dashboard'),
  MainTodo: () => import('@/views/Home/MainTodo/Todo'),
  approvalDetail: () => import('@/views/Mt/Approval/ApprovalDetail.vue'),
  /*  */
  Equipment: () => import('@/views/Equipment/EquipmentIndex'),
  Project: () => import('@/views/Project/ProjectIndex'),
  Storage: () => import('@/views/Project/SteelStorage/Storage/Storage'),
  Location: () => import('@/views/Project/SteelStorage/Storage/Location'),
  Stock: () => import('@/views/Project/SteelStorage/Stock/Stock'),
  StockLogIn: () => import('@/views/Project/SteelStorage/Stock/Stock'),
  StockLogOut: () => import('@/views/Project/SteelStorage/Stock/Stock'),
  StockApply: () => import('@/views/Project/SteelStorage/Stock/Stock'),
  ClientApply: () => import('@/views/Project/SteelStorage/Stock/Stock'),
  AlreadyStock: () => import('@/views/Project/SteelStorage/Stock/StockList'),
  StockStatistics: () => import('@/views/Project/SteelStorage/Stock/StockStatistics'),
  StockDeliveryApply: () => import('@/views/Project/SteelStorage/Storage/StockApplyList'),
  // 煤炭仓库
  MtStorageSetup: () => import('@/views/Project/MtStorage/Storage/Storage'),
  MtPortSetup: () => import('@/views/Project/MtStorage/Storage/Port'),
  MtLocation: () => import('@/views/Project/MtStorage/Storage/Location'),
  MtStockLogIn: () => import('@/views/Project/MtStorage/Stock/Stock'),
  MtStockLogOut: () => import('@/views/Project/MtStorage/Stock/Stock'),
  MtStockApply: () => import('@/views/Project/MtStorage/Stock/Stock'),
  MtStockManage: () => import('@/views/Project/MtStorage/Stock/StockManage'),
  MtStockIn: () => import('@/views/Project/MtStorage/Stock/Stock'),
  MtStockOut: () => import('@/views/Project/MtStorage/Stock/Stock'),
  stockMoveList: () => import('@/views/Project/MtStorage/StockMove/StockMoveList'),
  Library: () => import('@/views/Project/MtStorage/Storage/Library'),

  /*  */
  System: () => import('@/views/System/SystemIndex'),
  User: () => import('@/views/System/User/User'),
  Menu: () => import('@/views/System/Menu/Menu'),
  Role: () => import('@/views/System/Role/Role'),
  Back: () => import('@/views/System/Back/Back'),
  MtBack: () => import('@/views/System/Back/MtBack'),

  /* 日志 */
  Log: () => import('@/views/Log/LogIndex'),
  LoginInfor: () => import('@/views/Log/logininfor/LoginInfor'),
  OperLog: () => import('@/views/Log/OperLog/OperLog'),
  /* 采销管理 */
  Business: () => import('@/views/Business/BusinessIndex'),
  Projects: () => import('@/views/Business/Project/Project'),
  ProjectDetail: () => import('@/views/Business/Project/ProjectDetail'),
  Sole: () => import('@/views/Business/Purchase/Purchase'),
  Purchase: () => import('@/views/Business/Purchase/Purchase'),
  PurchaseDetail: () => import('@/views/Business/Purchase/Detail'),
  SubjectTemplate: () => import('@/views/Business/SubjectTemplate'),
  StorageLogistics: () => import('@/views/Business/StorageLogistics/StorageLogistics'),
  StorageLogisticsDetail: () => import('@/views/Business/StorageLogistics/StorageLogisticsDetail'),
  /* 煤炭管理 */
  MtProjects: () => import('@/views/Mt/MtProject/MtProject'),
  MtProjectDetail: () => import('@/views/Mt/MtProject/MtProjectDetail'),
  MtPurchase: () => import('@/views/Mt/MtBusiness/MtPurchase'),
  MtSole: () => import('@/views/Mt/MtBusiness/MtPurchase'),
  MtPurchaseDetail: () => import('@/views/Mt/MtBusiness/Detail'),
  /* 客户管理 */
  Consumer: () => import('@/views/Consumer/ConsumerIndex'),
  ConsumerList: () => import('@/views/Consumer/Consumer/Consumer'),
  ConsumerDetail: () => import('@/views/Consumer/Consumer/ConsumerDetail'),
  // 客户端页面
  Client: () => import('@/views/Client/ClientIndex'),
  ClientStock: () => import('@/views/Client/ClientIndex'),
  ClientStockList: () => import('@/views/Client/ClientStock/StockList'),
  ClientStorageList: () => import('@/views/Client/ClientStock/StorageList'),
  ClientApproval: () => import('@/views/Client/ClientStock/ClientApproval'),
  Detail: () => import('@/views/Client/System/Detail'),
  Jump: () => import('@/views/Client/System/Jump'),
  ResourceDetail: () => import('@/views/Client/Contract/ResourceDetail'),
  ContractManagement: () => import('@/views/Client/Contract/ContractList'),
  Capital: () => import('@/views/Client/Finance/Capital'),
  Invoice: () => import('@/views/Client/Finance/Invoice'),
  ClientDashboard: () => import('@/views/Client/Dashboard/Dashboard'),
  ClientUser: () => import('@/views/Client/System/User'),
  ContractSign: () => import('@/views/Client/Contract/ContractSign'),
  // ContractSingList: () => import('@/views/Client/Contract/ContractSingList'),
  // 审批模块
  Approval: () => import('@/views/Approval/Approval'),
  ApprovalAllList: () => import('@/views/Approval/ApprovalList/ApprovalList'),
  ApprovalLunch: () => import('@/views/Approval/ApprovalList/ApprovalList'),
  ApprovalSuccess: () => import('@/views/Approval/ApprovalList/ApprovalList'),
  ApprovalFail: () => import('@/views/Approval/ApprovalList/ApprovalList'),
  ApprovalOwn: () => import('@/views/Approval/ApprovalList/ApprovalList'),
  ApprovalTemplateList: () => import('@/views/Approval/ApprovalTemplateList/ApprovalTemplateList'),

  // ncc管理
  Ncc: () => import('@/views/Ncc/NccIndex'),
  NccLogList: () => import('@/views/Ncc/NccLogList/NccLogList'),

  // 台账管理
  Ledger: () => import('@/views/Ledger/LedgerIndex'),
  OperationLedger: () => import('@/views/Ledger/LedgerList/OperationLedger'),
  SaleOrdersLedger: () => import('@/views/Ledger/LedgerList/SaleOrdersLedger'),
  OverDateLedger: () => import('@/views/Ledger/LedgerList/OverDateLedger'),
  MtOverDateLedger: () => import('@/views/Ledger/LedgerList/MtOverDateLedger'),
  MtStorageLedger: () => import('@/views/Ledger/LedgerList/MtStorageLedger'),
  procurementContract: () => import('@/views/Ledger/LedgerList/procurementContract'),
  salesGrossProfit: () => import('@/views/Ledger/LedgerList/salesGrossProfit'),
  materialDetails: () => import('@/views/Ledger/LedgerList/materialDetails'),
  procureAndSales: () => import('@/views/Ledger/LedgerList/procureAndSales'),
  salesContract: () => import('@/views/Ledger/LedgerList/salesContract'),

  // 合同管理
  Contract: () => import('@/views/Contract/ContractIndex'),
  contractList: () => import('@/views/Contract/ContractList/ContractList'),

  // 财务管理
  mtPurchase: () => import('@/views/MtFinancialManagement/mtPurchase'),
  mtSale: () => import('@/views/MtFinancialManagement/mtSale'),
  mtFinancialDetail: () => import('@/views/MtFinancialManagement/mtFinancialDetail'),
  FinancialDetail: () => import('@/views/MtFinancialManagement/FinancialDetail'),
  // 财务管理(钢材)
  GCPurchase: () => import('@/views/FinancialManagement/GCPurchase'),

  // 发票管理
  mtInvoiceList: () => import('@/views/Invoice/MtInvoice/InvoiceList.vue'),
  InvoiceList: () => import('@/views/Invoice/Invoice/InvoiceList.vue'),

  // 客户端煤炭合同管理
  MtContractManagement: () => import('@/views/Mt/Client/Contract/MtContractList'),

  // 仓库管理-入库管理
  warehousingPlan: () => import('@/views/Project/warehouse/warehousingPlan/warehousingPlan'), //入库计划
  warehousingTask: () => import('@/views/Project/warehouse/warehousingTask/warehousingTask'), //入库作业
  warehousingDocument: () => import('@/views/Project/warehouse/warehousingDocument/warehousingDocument'), //入库单据
  warehousingStockPending: () => import('@/views/Project/warehouse/warehousingStockPending/warehousingStockPending'), //待入库列表


  // 仓库管理-出库管理
  outboundPlan: () => import('@/views/Project/outbound/outboundPlan/outboundPlan'), //出库计划
  outboundTask: () => import('@/views/Project/outbound/outboundTask/outboundTask'), //出库作业
  outboundDocument: () => import('@/views/Project/outbound/outboundDocument/outboundDocument'), //出库单据

  warehousingPlanDetail: () => import('@/views/Project/warehouse/warehousingPlan/warehousingPlanDetail'), //入库计划详情
  warehousingTaskDetail: () => import('@/views/Project/warehouse/warehousingTask/warehousingTaskDetail'), //入库作业详情
  warehousingDocumentDetail: () => import('@/views/Project/warehouse/warehousingDocument/warehousingDocumentDetail'), //入库单据详情


  outboundPlanDetail: () => import('@/views/Project/outbound/outboundPlan/outboundPlanDetail'), //出库计划详情
  outboundTaskDetail: () => import('@/views/Project/outbound/outboundTask/outboundTaskDetail'), //出库作业详情
  outboundDocumentDetail: () => import('@/views/Project/outbound/outboundDocument/outboundDocumentDetail'), //出库单据详情

  record: () => import('@/views/Project/changeGoods/record'), //货权变更记录
  approvalList: () => import('@/views/Project/changeGoods/approvalList'), //审批列表


  // 仓库管理-结算管理
  settlementManagement: () => import('@/views/Project/settlementManagement/settlementManagement'), //结算管理
  item: () => import('@/views/Project/settlementManagement/feeSettings/feeSettings'), //结算项目
  rule: () => import('@/views/Project/settlementManagement/billingRules/billingRules'), //结算规则
  costDetail: () => import('@/views/Project/settlementManagement/feeBreakdown/feeBreakdown'), //费用明细
  bill: () => import('@/views/Project/settlementManagement/billingManagement/billingManagement'), //账单管理

  // 移库管理
  relocation: () => import('@/views/Project/migration/migration'), //移库管理
  relocationList: () => import('@/views/Project/migration/migrationList/migrationList'), //移库列表
  relocationDetail: () => import('@/views/Project/migration/migrationDetail/migrationDetail'), //移库详情
  inventoryList: () => import('@/views/Project/inventory/inventoryList/inventoryList'), //盘库管理
  inventoryDetail: () => import('@/views/Project/inventory/inventoryDetail/inventoryDetail'), //盘库管理
  processList: () => import('@/views/Project/processPlan/processList/processPlan'), //加工计划列表
  processDetail: () => import('@/views/Project/processPlan/processList/processPlanDetail'), //加工计划详情

  // 新 - 煤炭系统
  CoalMt: () => import('@/views/CoalMt/CoalMt'),
  Protocal: () => import('@/views/CoalMt/Protocal/Protocal'), // 框架协议
  CoalPurchase: () => import('@/views/CoalMt/CoalPurchase/CoalPurchase'), // 采购管理
  SellManager: () => import('@/views/CoalMt/SellManager/SellManager'), // 销售管理
  PurchaseId: () => import('@/views/CoalMt/PurchaseId/PurchaseId'), // 采购详情
  SellId: () => import('@/views/CoalMt/SellId/SellId'), // 销售详情
  Sell: () => import('@/views/CoalMt/Sell/Sell'), // 保证金管理
  Cost: () => import('@/views/CoalMt/Cost/Cost'), //  费用管理
  Warehouse: () => import('@/views/CoalMt/Warehouse/Warehouse'), // 仓库管理
  Inbound: () => import('@/views/CoalMt/Warehouse/Pages/Inbound/Inbound'), // 入库管理
  Outbound: () => import('@/views/CoalMt/Warehouse/Pages/Outbound/Outbound'), // 出库管理
  File: () => import('@/views/CoalMt/File/File'), // 文档列表
  Basic: () => import('@/views/CoalMt/Basic/Basic'), // 基础信息管理
  CostType: () => import('@/views/CoalMt/CostType/CostType'), // 费用类型管理
  TransportTool: () => import('@/views/CoalMt/TransportTool/TransportTool'), // 运输工具管理
  AttachmentType: () => import('@/views/CoalMt/AttachmentType/AttachmentType'), // 附件类型管理
  Port: () => import('@/views/CoalMt/Warehouse/Pages/Port/Port'), // 库位管理
  Quality: () => import('@/views/CoalMt/Quality/Quality'), // 质检管理
  Inventory: () => import('@/views/CoalMt/Warehouse/Pages/Inventory/Inventory'), //库存查询
  MakeInventory: () => import('@/views/CoalMt/Warehouse/Pages/MakeInventory/MakeInventory'), //盘库管理
  CoalApprovalDetail: () => import('@/views/CoalMt/CoalApprovalDetail/CoalApprovalDetail'), // 新煤炭待办
}
export default (key) => {
  return routerViewMap[key] || null
}
